import React from 'react'

import { x } from 'helpers'
import { Heading, FrequentlyAskedQuestions, OutlinedText, PlusIcon, Text, ColorBars } from 'components'

const FAQ = () => {
  return (
    <>
      <x.main backgroundColor='mvrkDarkGray' position='relative'>
        <x.div pt={{ xs: '50px', sm: '100px' }} className='max-content-width' margin='0 auto'>
          <x.div py={{ xs: '2rem', md: '6rem' }} px={{ xs: '1rem', sm: '2rem' }} display='flex' flexDirection='column'>
            <x.div display='flex' alignItems='center'>
              <PlusIcon mr='10px' />
              <x.span color='primary'>Vx360 3D PLATFORM</x.span>
            </x.div>
            <Heading fontSize={{ xs: '2.25rem', sm: '3rem', md: '4.5rem' }} lineHeight={{ _: '90%' }} my='0.5rem'>
              <OutlinedText as='span' display='block'>
                Frequently
              </OutlinedText>
              <x.span display='block'>asked questions</x.span>
            </Heading>
            <Text>
              Your virtual event awaits, but in the case you still have questions we’ve put together a handful of
              questions and answers that might help. If questions still arise don’t hesitate to reach out to our team or
              schedule a demo.
            </Text>
          </x.div>
        </x.div>
        <x.div backgroundColor='mvrkDarkerGray'>
          <FrequentlyAskedQuestions />
        </x.div>
      </x.main>
      <ColorBars position='absolute' top='100%' right='0' />
    </>
  )
}

export default FAQ
